import React, { useContext } from 'react';
import { signOut } from 'aws-amplify/auth';
import { startChat } from './chat-jq';
import { showChatReasons } from './chat-reasons-v2';
import { ChatReasonContext, useHomePageContext } from '../../context/HomePageContext';
import {getAuthTokenOrLogOut} from "../util";

type ChatProps = { className?: string; };

export default function Chat({className}: ChatProps) {
  const { homePageState, setHomePageState } = useHomePageContext();
  const { reason, setReason } = useContext(ChatReasonContext);

  function handleBrandSelection(e: any) {
    setReason('');
    setHomePageState({
      ...homePageState,
      brandSelected: e.target.value,
    });
    showChatReasons(e.target.value, (chatReasonSelected: string) => {
      setReason(chatReasonSelected);
    });
  }

  return (
    <form name="contactDetail" id="contactDetails" className={`login100-form ${className}`} action="/index.html" onSubmit={ (e) => e.preventDefault() } data-testid="chat-form">
      <div className="brand-options-container">
        <p className="chat-reasons-p" data-testid="select-brand-question">What brand are you contacting about?</p>
        <div className="brand-container">
          <label htmlFor="OVO" className="brand-radio-btn">OVO</label>
          <input type="radio" className="brand-radio-btn" id="OVO" name="brand" value="OVO" onChange={handleBrandSelection}/>
        </div>
        <hr className="solid"/>
        <div className="brand-container">
          <label htmlFor="BOOST" className="brand-radio-btn">BOOST</label>
          <input type="radio" className="brand-radio-btn" id="BOOST" name="brand" value="BOOST" onChange={handleBrandSelection}/>
        </div>
      </div>
      <div className="chat-reasons-container" style={{ display: 'none' }}>
        <p className="chat-reasons-p">What issue are you having?</p>
        <div id="chat-reasons-dropdown"></div>
      </div>
      <div className="btn-flex-container buttons-fixed">
        <div className="wrap-login100 chatBtns">
          <div className="container-login100-form-btn">
            {(reason !== 'Engineer comms checker' && homePageState.brandSelected) &&
              <button className={`primaryBtn m-b-10 ${reason ? 'primaryButtonEnabled' : 'primaryButtonDisabled'}`} id="startChatFirstPage"
                      onClick={async () => {
                        const {userEmail, token} = await getAuthTokenOrLogOut();
                        if (token) {
                          startChat('', userEmail, token, '');
                        }
                      }}
                      disabled={!homePageState.brandSelected && !reason}>Start Chat
              </button>
            }
            <button className="secondaryBtn" id="logoutFirstPage" onClick={ async () => { await signOut(); }} data-testid="log-out-btn">Log Out</button>
          </div>
        </div>
      </div>
    </form>
  );
}
