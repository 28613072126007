import React from 'react';
import CommsCheck from '../comms-check/CommsCheck';
import Chat from '../chat/Chat';
import {
  ChatReasonContext,
  HomePageContext,
  homePageInitialState,
  HomePageState,
} from '../../context/HomePageContext';
import { Inputs, InputsContext } from '../../context/InputsContext';
import { getParamsFromURL } from '../chat/chat-jq';

export default function HomePage() {
  const [homePageState, setHomePageState] = React.useState<HomePageState>(homePageInitialState);
  const [reason, setReason] = React.useState<string>('');
  const params = getParamsFromURL();
  const [inputs, setInputs] = React.useState<Inputs>({
    mpxn: params.mpxn ?? '',
    msn: params.msn ?? '',
    mpxnError: '',
    msnError: '',
  });

  return (
    <HomePageContext.Provider value={{ homePageState, setHomePageState }}>
      <ChatReasonContext.Provider value={{ reason, setReason }}>
        <Chat className={homePageState.commsCheckInProgress ? 'displayNone' : undefined}/>
        <span id="accessDeniedError" style={{ display: 'none' }} className="errorMessage">Oops, try again in 10 seconds</span>
        <InputsContext.Provider value={{ inputs, setInputs }}>
          {reason === 'Engineer comms checker' && <CommsCheck/>}
        </InputsContext.Provider>
      </ChatReasonContext.Provider>
    </HomePageContext.Provider>
  );
}
