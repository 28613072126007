import {fetchAuthSession, signOut} from "aws-amplify/auth";

interface XrinvRequestData {
  // eslint-disable-next-line camelcase
  xrinv_in_01: any
}

interface XritmRequestData {
  // eslint-disable-next-line camelcase
  xritm_in_01: any
}

interface XrdlgRequestData {
  // eslint-disable-next-line camelcase
  xrdlg_in_01: any
}

function buildGenerateRequest(data: XrinvRequestData[] | XritmRequestData[] | XrdlgRequestData[], token: string | null) {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ data }),
  };
}

export function validateMpxn(mpxn: string): string {
  if (mpxn.length === 0) {
    return 'Error: Please enter the MPxN';
  }

  if (!/^\d{6,13}$/.test(mpxn)) {
    return 'Error: Please check the MPxN';
  }

  return '';
}

export function validateMsn(msn: string): string {
  if (msn.length === 0) {
    return 'Error: Please enter the MSN';
  }

  if (!/^[a-zA-Z0-9]{8,14}$/.test(msn)) {
    return 'Error: Please check the MSN';
  }

  return '';
}

export function buildXrinvRequest(mpxn: string, token: string | null): any {
  const xrinvRequestData: XrinvRequestData = {
    xrinv_in_01: {
      Identifier: mpxn,
    },
  };

  return buildGenerateRequest([xrinvRequestData], token);
}

export function buildXritmRequest(mpxn: string, msn: string, token: string | null): any {
  const xritmRequestData: XritmRequestData = {
    xritm_in_01: {
      ImportMPxN: mpxn,
      MSN: msn,
    },
  };

  return buildGenerateRequest([xritmRequestData], token);
}

export function buildXrdlgRequest(mpxn: string, chfGuid: string | undefined, token: string | null): any {
  const xrdlgRequestData: XrdlgRequestData = {
    xrdlg_in_01: {
      ImportMPxN: mpxn,
      Identifier: chfGuid,
    },
  };

  return buildGenerateRequest([xrdlgRequestData], token);
}

export function buildGetXmlRequest(data: any, token: string | null) {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
}

export const getAuthTokenOrLogOut = async () => {
  try {
    const session = await fetchAuthSession();
    if (!session.tokens) {
      console.error('Token has expired or an error occurred:');
      return { userEmail: null, token: null };
    }
    const token = session.tokens?.accessToken.toString() ?? null;
    const userEmail = session.tokens?.signInDetails?.loginId;
    return { userEmail, token };
  } catch (error) {
    console.error('Token has expired or an error occurred:', error);

    await signOut();
    console.log('User has been logged out');
    return { userEmail: null, token: null };
  }
};
